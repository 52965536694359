<template>
  <q-card style="width: 800px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        整项目配置表
        <small class="q-ml-sm text-size-sm">
          {{ TgyBaseConfigEntity.TgyBaseConfigName }}
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <!-- <q-btn-toggle
          v-model="isOve"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '不是全局', value: 0 },
            { label: '是全局', value: 1 },
          ]"
        /> -->
        <select_hospInfo
          v-if="isOve == 0"
          :dense="true"
          outlined
          class="col-6"
          label="选择机构"
          v-model="TgyBaseConfigEntity.HospID"
          v-model:valueCode="TgyBaseConfigEntity.HospID"
        />
        <q-btn-toggle
          v-model="TgyBaseConfigEntity.ConfigIsOff"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '开启', value: 1 },
            { label: '关闭', value: 0 },
          ]"
        />
      </div>
    <!-- </q-card-section>
    <q-card-section class="q-pa-sm"> -->
      <div class="row q-gutter-sm q-mb-sm q-pr-sm">
        <q-input
          label="key值"
          dense
          outlined
          class="col-3"
          v-model="TgyBaseConfigEntity.ConfigKey"
        />
        <q-input
          label="value值"
          dense
          outlined
          class="col-2"
          v-model="TgyBaseConfigEntity.ConfigValue"
        />
        <q-input
          label="类型"
          dense
          outlined
          class="col-2"
          v-model="TgyBaseConfigEntity.ConfigType"
        />
        <q-input
          label="备注"
          dense
          outlined
          class="col-4"
          v-model="TgyBaseConfigEntity.ConfigRemark"
        />

        <q-editor
          v-model="TgyBaseConfigEntity.ConfigText"
          class="col-12"
          height="300px"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
export default {
  components: {
    select_hospInfo,
  },
  props: {},
  computed: {
    ...mapState("TgyBaseConfig", [
      "TgyBaseConfigShowDlg",
      "TgyBaseConfigEntity",
      "TgyBaseConfigList",
    ]),
  },
  data() {
    return {
      isOve: 0,
    };
  },
  mounted() {
    // if (this.TgyBaseConfigEntity.HospID == -1) {
    //   this.isOve = 1;
    // }
  },
  methods: {
    ...mapActions("TgyBaseConfig", [
      "actUpdateTgyBaseConfig",
      "actGetTgyBaseConfigList",
    ]),
    onOK() {
    //   if (this.isOve == 1) {
    //     this.TgyBaseConfigEntity.HospID = -1;
    //   }
      this.actUpdateTgyBaseConfig({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.TgyBaseConfigEntity,
      }).then((res) => {
        if (res.Code == 0) {
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
